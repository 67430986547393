import React, { useState, useEffect } from "react";
import db, { storage } from "./Firebase";
import "./Homepage.css";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Axios from "axios";
import { Audio, Grid } from "react-loader-spinner";
import auth, { database } from "./Firebaseauth";
import userIcon from "./usersolid.png";
import { debounce } from "lodash";

function Homepage() {
  var { passeduserid } = useParams();

  const [isloading, setisloading] = useState(false);
  const [isauthorized, setisauthorized] = useState(false);

  const [isadmin, setisadmin] = useState(false);
  const [firsttimefetching, setfirsttimefetching] = useState(true);

  const [newsLetterChecked, setNewsLetterChecked] = useState(false);

  const navigate = useNavigate();

  const [modalimage, setmodalimage] = useState(null);
  const [modalimagetype, setmodalimagetype] = useState("image");
  const [currentEditingValue, setcurrentEditingValue] = useState("");
  const [currentEditingValueId, setcurrentEditingValueId] = useState("");
  const [displaycategory, setdisplaycategory] = useState("All");

  const [searchterm, setsearchterm] = useState("");

  const [allcontents, setallcontents] = useState([]);
  const [allfilteredcontents, setallfilteredcontents] = useState([]);

  const [rawfile, setrawfile] = useState(null);
  const [currentuser, setcurrentuser] = useState(null);
  const [username, setusername] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [tagline, settagline] = useState("");
  const [tagline2, settagline2] = useState("");
  const [tagline3, settagline3] = useState("");

  const [noofdownloadsleft, setnoofdownloadsleft] = useState(0);

  const [oldtagline, setoldtagline] = useState("");
  const [oldtagline2, setoldtagline2] = useState("");
  const [oldtagline3, setoldtagline3] = useState("");

  const [currentlyuploading, setcurrentlyuploading] = useState(false);

  const [newcontentcategory, setnewcontentcategory] = useState("Video");
  const [newcontentlogoposiiton, setnewcontentlogoposiiton] =
    useState("Top Left");
  const [newcontenttitle, setnewcontenttitle] = useState("");
  const [newrawfile, setnewrawfile] = useState(null);
  const [selectednewrawfile, setselectednewrawfile] = useState(null);
  const [contentsloaded, setcontentsloaded] = useState(false);
  const [selectedalbumtoopen, setselectedalbumtoopen] = useState(null);
  const [showpositionmodal, setshowpositionmodal] = useState(false);
  const [positionmodalposition, setpositionmodalposition] = useState("TL");
  const [tryingtodownloaditem, settryingtodownloaditem] = useState(null);
  const BASE_URL = "https://apis.volatilitygame.com/watermark";

  const MARGIN_TOP_MODAL = window.innerWidth > 768 ? "15vh" : "5vh";
  const WIDTH_TAG_LINE = window.innerWidth > 768 ? "30%" : "100%";

  useEffect(() => {
    if (passeduserid) {
      setcurrentuser(passeduserid);
      database
        .collection("users")
        .doc(passeduserid)
        .get()
        .then((existinguser) => {
          if (
            existinguser.data().canusevgcreatives === true ||
            existinguser.data().usertype === "VG Creatives"
          ) {
            console.log("Set it to true");
            setisauthorized(true);
            db.collection("users")
              .doc(passeduserid)
              .get()
              .then((info) => {
                if (!info.exists) {
                } else {
                  console.log("Reached here");
                  setusername(info.data().name);
                  setSelectedImage(info.data().logourl);
                  setnoofdownloadsleft(info.data().noofdownloadsleft);
                  settagline(info.data().tagline);
                  settagline2(info.data().tagline2);
                  settagline3(info.data().tagline3);
                  setoldtagline(info.data().tagline);
                  setoldtagline2(info.data().tagline2 || "");
                  setoldtagline3(info.data().tagline3 || "");
                  setisadmin(info.data().isadmin);
                }
              })
              .catch((efd) => {
                console.log(efd);
              });
          } else {
            setisauthorized(false);
          }
        });
    } else {
    }
  }, []);

  useEffect(() => {
    if (isauthorized === true && allcontents.length === 0) {
      fetchallcontents();
    }
  }, [isauthorized]);

  useEffect(() => {
    if (!firsttimefetching) {
      db.collection("users").doc(passeduserid).update({
        noofdownloadsleft: noofdownloadsleft,
      });
    }
    setfirsttimefetching(false);
  }, [noofdownloadsleft]);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: 1030,
    },
  };

  const customStyles2 = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  const customStyles3 = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: 1030,
    },
  };
  const customStyles4 = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  const customStyles5 = {
    content: {
      top: "0%",
      left: "0%",
      right: "0%",
      bottom: "0%",
      marginRight: "0%",
      transform: "translate(0%, 0%)",
      zIndex: 1020,
    },
  };
  const customStyles6 = {
    content: {
      top: "0%",
      left: "0%",
      right: "0%",
      bottom: "0%",
      marginRight: "0%",
      transform: "translate(0%, 0%)",
      zIndex: 1020,
    },
  };

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  const processupload = (e) => {
    if (
      rawfile == null &&
      oldtagline === tagline &&
      oldtagline2 === tagline2 &&
      oldtagline3 === tagline3
    ) {
      setIsOpen(false);
    } else if (rawfile == null) {
      setcurrentlyuploading(true);
      db.collection("users")
        .doc(currentuser)
        .update({
          tagline: tagline,
          tagline2: tagline2,
          tagline3: tagline3,
        })
        .then((done) => {
          alert("Updated");
          setIsOpen(false);
          settagline(tagline);
          settagline2(tagline2);
          settagline3(tagline3);
          setcurrentlyuploading(false);
        })
        .catch((err) => {
          setcurrentlyuploading(false);
        });
    } else {
      setcurrentlyuploading(true);
      storage
        .ref()
        .child("users/" + currentuser + "/")
        .put(rawfile)
        .then((snapshot) => {
          storage
            .ref()
            .child("users/" + currentuser + "/")
            .getDownloadURL()
            .then((downloadURL) => {
              db.collection("users")
                .doc(currentuser)
                .update({
                  logourl: downloadURL,
                  tagline: tagline,
                  tagline2: tagline2,
                  tagline3: tagline3,
                })
                .then((done) => {
                  alert("Updated");
                  setIsOpen(false);
                  settagline(tagline);
                  settagline2(tagline2);
                  settagline3(tagline3);
                  setSelectedImage(downloadURL);
                  setcurrentlyuploading(false);
                })
                .catch((err) => {
                  setcurrentlyuploading(false);
                });
            })
            .catch((efg) => {
              setcurrentlyuploading(false);
            });
        });
    }
  };

  let subtitle2;
  const [modalIsOpen2, setIsOpen2] = React.useState(false);

  function openModal2() {
    setIsOpen2(true);
  }

  function afterOpenModal2() {
    // references are now sync'd and can be accessed.
    subtitle2.style.color = "#f00";
  }

  function closeModal2() {
    setIsOpen2(false);
  }

  let subtitle3;
  const [modalIsOpen3, setIsOpen3] = React.useState(false);

  function openModal3() {
    setIsOpen3(true);
  }

  function afterOpenModal3() {
    // references are now sync'd and can be accessed.
    subtitle3.style.color = "#f00";
  }

  function closeModal3() {
    setIsOpen3(false);
  }

  let subtitle4;
  const [modalIsOpen4, setIsOpen4] = React.useState(false);

  function openModal4() {
    setIsOpen4(true);
  }

  function afterOpenModal4() {
    // references are now sync'd and can be accessed.
    subtitle4.style.color = "#f00";
  }

  function closeModal4() {
    setIsOpen4(false);
  }

  let subtitle5;
  const [modalIsOpen5, setIsOpen5] = React.useState(false);
  function openModal5() {
    setIsOpen5(true);
  }

  function afterOpenModal5() {
    // references are now sync'd and can be accessed.
    // subtitle5.style.color = "#f00";
  }

  function closeModal5() {
    setIsOpen5(false);
  }

  let subtitle6;
  const [modalIsOpen6, setIsOpen6] = React.useState(false);

  function openModal6() {
    setIsOpen6(true);
  }

  function afterOpenModal6() {
    // references are now sync'd and can be accessed.
    subtitle6.style.color = "#f00";
  }

  function closeModal6() {
    setIsOpen6(false);
  }

  const filtercontents = debounce((search) => {
    const filtered = allcontents.filter((item) =>
      item.title.toLowerCase().includes(search.toLowerCase())
    );
    setallfilteredcontents(filtered);
  }, 1000);

  const processnewupload = () => {
    if (currentuser == null) {
      navigate("/");
      return;
    }
    if (newcontenttitle == "") {
      if (newcontentcategory === "Album") {
        alert("Please enter Album name");
      } else {
        alert("Please enter content title");
      }
    } else if (newcontentcategory === "Album") {
      var currentTimeInSeconds = Math.floor(Date.now() / 1000);
      var allpromises = [];
      if (newrawfile === null) {
        alert("Please select images");
        return;
      }
      setcurrentlyuploading(true);
      newrawfile.forEach((rawfile) => {
        if (rawfile.type.substring(0, 5) == "image") {
          var x = new Promise((resolve, reject) => {
            storage
              .ref()
              .child("content/" + currentTimeInSeconds + "/" + rawfile.name)
              .put(rawfile)
              .then((snapshot) => {
                storage
                  .ref()
                  .child("content/" + currentTimeInSeconds + "/" + rawfile.name)
                  .getDownloadURL()
                  .then((downloadURL) => {
                    resolve(downloadURL);
                  })
                  .catch((efg) => {
                    setcurrentlyuploading(false);
                    reject(efg);
                  });
              })
              .catch((efg) => {
                setcurrentlyuploading(false);
                reject(efg);
              });
          });
          allpromises.push(x);
        }
      });
      if (allpromises.length === 0) {
        alert("Please choose images to be uploaded");
        allpromises = [];
        return;
      }
      Promise.allSettled(allpromises).then((resp) => {
        db.collection("content")
          .doc("" + currentTimeInSeconds)
          .set({
            title: newcontenttitle,
            logoposiiton: newcontentlogoposiiton,
            category: newcontentcategory,
            contenturl: resp,
            createdon: currentTimeInSeconds,
          })
          .then((done) => {
            alert("Uploaded");
            setIsOpen2(false);
            setcurrentlyuploading(false);
            setselectednewrawfile(null);
            setnewrawfile(null);
          })
          .catch((err) => {
            alert(err);
            setcurrentlyuploading(false);
          });
      });
    } else if (
      newrawfile.type.substring(0, 5) == "image" ||
      newrawfile.type.substring(0, 5) == "video" ||
      newrawfile.type.substring(12, 15) == "pdf"
    ) {
      setcurrentlyuploading(true);
      var currentTimeInSeconds = Math.floor(Date.now() / 1000);
      storage
        .ref()
        .child("content/" + currentTimeInSeconds + "/" + newrawfile.name)
        .put(newrawfile)
        .then((snapshot) => {
          storage
            .ref()
            .child("content/" + currentTimeInSeconds + "/" + newrawfile.name)
            .getDownloadURL()
            .then((downloadURL) => {
              db.collection("content")
                .doc("" + currentTimeInSeconds)
                .set({
                  title: newcontenttitle,
                  logoposiiton: newcontentlogoposiiton,
                  category: newcontentcategory,
                  contenturl: downloadURL,
                  createdon: currentTimeInSeconds,
                  isnewsletter:
                    newcontentcategory.toLowerCase() === "pdf"
                      ? newsLetterChecked
                      : false,
                })
                .then((done) => {
                  alert("Uploaded");
                  setIsOpen2(false);
                  setcurrentlyuploading(false);
                  setselectednewrawfile(null);
                  setnewrawfile(null);
                })
                .catch((err) => {
                  alert(err);
                  setcurrentlyuploading(false);
                });
            })
            .catch((efg) => {
              setcurrentlyuploading(false);
            });
        });
    } else {
      alert("Unsupported File. Only Images and Videos are allowed");
    }
  };

  const fetchallcontents = () => {
    db.collection("content")
      .orderBy("createdon", "desc")
      .get()
      .then((allsnaps) => {
        var tmp = [];
        allsnaps.docs.map((eachsnap) => {
          tmp.push({ ...eachsnap.data(), id: eachsnap.id });
        });
        setallcontents(tmp);
        setallfilteredcontents(tmp);
        setcontentsloaded(true);
      });
  };

  const finalisedownload = () => {
    if (selectedImage === null || tagline === "") {
      setIsOpen(true);
      setIsOpen2(false);
      setIsOpen3(false);
      setIsOpen4(false);
      setIsOpen5(false);
      setIsOpen6(false);
      setshowpositionmodal(false);
      alert("You need to upload your logo and tagline before downloading");
    } else if (tryingtodownloaditem.type == "all") {
      tryingtodownloaditem.contenturl.forEach(async (url) => {
        await downloadImage(url.value);
      });
    } else if (tryingtodownloaditem.type == "pdf") {
      downloadpdf(tryingtodownloaditem.id);
    } else if (tryingtodownloaditem.type == "image") {
      downloadImage(tryingtodownloaditem.contenturl);
    } else {
      downloadVideo(tryingtodownloaditem.id);
    }
  };

  const downloadpdf = async (id) => {
    var fm = allcontents.filter((df) => df.id == id);
    if (fm.length > 0) {
      var pdfurl = fm[0].contenturl;
      var watermarklogourl = selectedImage;
      var watermarktext = tagline;
      if (tagline2 !== "") {
        watermarktext = watermarktext + "\n" + tagline2;
      }
      if (tagline3 !== "") {
        watermarktext = watermarktext + "\n" + tagline3;
      }
      setisloading(true);
      var paramobj = {
        imageUrl: watermarklogourl,
        pdfUrl: pdfurl,
        waterMarkText: watermarktext,
        wmPosition: positionmodalposition,
      };
      const responseType = {
        responseType: "blob",
      };
      closeModal();
      closeModal2();
      closeModal3();
      closeModal4();
      closeModal5();
      closeModal6();
      setshowpositionmodal(false);

      var absurl = "";
      if (fm[0]?.isnewsletter === true) {
        absurl = `${BASE_URL}/api/v1/newsletter`;
      } else {
        absurl = `${BASE_URL}/api/v1/watermark-pdf`;
      }
      try {
        const res = await Axios.post(absurl, paramobj, responseType);
        setisloading(false);
        if (res) {
          const data = new Blob([res.data]);
          const url = window.URL.createObjectURL(data);
          const tempLink = document.createElement("a");
          tempLink.href = url;
          tempLink.setAttribute("download", "output.pdf");
          tempLink.click();
          setnoofdownloadsleft(noofdownloadsleft - 1);
        }
      } catch (err) {
        setisloading(false);
        alert("Download Failed, Please try again later !");
      }
    }
  };

  const downloadVideo = async (id) => {
    var fm = allcontents.filter((df) => df.id == id);
    if (fm.length > 0) {
      var videourl = fm[0].contenturl;
      var watermarklogourl = selectedImage;
      var combinedtext = tagline;
      if (tagline2 !== "") {
        combinedtext = combinedtext + "\n" + tagline2;
      }
      if (tagline3 !== "") {
        combinedtext = combinedtext + "\n" + tagline3;
      }
      setisloading(true);
      const body = {
        imageUrl: watermarklogourl,
        videoUrl: videourl,
        waterMarkText: combinedtext,
        wmPosition: positionmodalposition,
      };

      const responseType = {
        responseType: "blob",
      };
      closeModal();
      closeModal2();
      closeModal3();
      closeModal4();
      closeModal5();
      closeModal6();
      setshowpositionmodal(false);
      try {
        const res = await Axios.post(
          `${BASE_URL}/api/v1/watermark-video`,
          body,
          responseType
        );
        setisloading(false);

        if (res) {
          const data = new Blob([res.data]);
          const url = window.URL.createObjectURL(data);
          const tempLink = document.createElement("a");
          tempLink.href = url;
          tempLink.setAttribute("download", "output.mp4");
          tempLink.click();
          setnoofdownloadsleft(noofdownloadsleft - 1);
        }
      } catch (err) {
        setisloading(false);
        alert("Download Failed, Please try again later !");
      }
    }
  };

  const downloadImage = async (imageurl) => {
    var watermarklogourl = selectedImage;
    var combinedtext = tagline;
    if (tagline2 !== "") {
      combinedtext = combinedtext + "\n" + tagline2;
    }
    if (tagline3 !== "") {
      combinedtext = combinedtext + "\n" + tagline3;
    }
    setisloading(true);
    closeModal();
    closeModal2();
    closeModal3();
    closeModal4();
    closeModal5();
    closeModal6();
    setshowpositionmodal(false);

    const body = {
      imageUrl: imageurl,
      wmImageUrl: watermarklogourl,
      waterMarkText: combinedtext,
      wmPosition: positionmodalposition,
    };
    Axios.defaults.headers.post["Content-Type"] =
      "application/x-www-form-urlencoded";
    let url1 = `${BASE_URL}/api/v1/watermark-image`;
    // url1 = "https://customimage-2ra7kmof4q-uc.a.run.app";
    const responseType = {
      responseType: "blob",
    };

    try {
      const res = await Axios.post(url1, body, responseType);

      setisloading(false);
      if (res) {
        const data = new Blob([res.data]);
        const url = window.URL.createObjectURL(data);
        const tempLink = document.createElement("a");
        tempLink.href = url;
        tempLink.setAttribute("download", "output.png");
        tempLink.click();
        setnoofdownloadsleft(noofdownloadsleft - 1);
      }
    } catch (err) {
      setisloading(false);
      alert("Download Failed, Please try again later !");
    }
  };

  function doanloadalltapped() {
    db.collection("users")
      .doc(currentuser)
      .get()
      .then((fg) => {
        if (
          fg.data().noofdownloadsleft > selectedalbumtoopen.contenturl.length
        ) {
          settryingtodownloaditem({ ...selectedalbumtoopen, type: "all" });
          setshowpositionmodal(true);
        } else {
          setIsOpen5(false);
          setIsOpen4(true);
        }
      });
  }

  function downloadrequested(item, type) {
    db.collection("users")
      .doc(currentuser)
      .get()
      .then((fg) => {
        if (fg.data().noofdownloadsleft > 0) {
          settryingtodownloaditem({ ...item, type: type });
          setshowpositionmodal(true);
          // if(type == "pdf") {
          //     downloadpdf(id);
          // }
          // else if(type == "video") {
          //     downloadVideo(id);
          // }
          // else {
          // var url = "https://us-central1-dynamicmediatools-55686.cloudfunctions.net/customimage?userid="+currentuser+"&mediaid="+id+"&position=northeast";
          // console.log(url);
          // window.open(
          //     url,
          //     '_blank' // <- This is what makes it open in a new window.
          //   );
          // Axios.get(url,{responseType: 'blob'}).then(response => {
          //     const reader = new FileReader();
          //     const thumbnail =  blobToDataURL(response);
          //     Promise.resolve(thumbnail).then(yooo => {
          //         console.log("All done");
          //         console.log(yooo);
          //         setdownloadedfile(yooo);
          //     }).catch(errrr => {

          //     })

          // }).catch(err => {
          //     console.log(err);
          // })
          // }
        } else {
          setIsOpen4(true);
        }
      });
  }

  async function blobToDataURL(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = () => reject(reader.error);
      reader.onabort = () => reject(new Error("Read aborted"));
      reader.readAsDataURL(blob);
    });
  }

  const openalbum = (album) => {
    setnewcontentcategory("Album");
    setselectedalbumtoopen(album);
    setIsOpen5(true);
  };

  const editname = (media, type) => {
    setcurrentEditingValue(media?.title);
    setcurrentEditingValueId(media?.id);
    openModal6();
  };

  const editthisvalue = () => {
    db.collection("content")
      .doc(currentEditingValueId)
      .update({
        title: currentEditingValue,
      })
      .then((done) => {
        alert("Title Updated");
        closeModal6();
        fetchallcontents();
        setcurrentEditingValue("");
        setcurrentEditingValueId(0);
      });
  };

  const uploadexistingalbum = () => {
    var currentTimeInSeconds = Math.floor(Date.now() / 1000);
    var allpromises = [];
    if (newrawfile && newrawfile.length > 0) {
      setisloading(true);
    } else {
      alert("Please select any image file before uploading");
      return;
    }
    newrawfile.forEach((rawfile) => {
      if (rawfile.type.substring(0, 5) == "image") {
        var x = new Promise((resolve, reject) => {
          storage
            .ref()
            .child("content/" + currentTimeInSeconds + "/" + rawfile.name)
            .put(rawfile)
            .then((snapshot) => {
              storage
                .ref()
                .child("content/" + currentTimeInSeconds + "/" + rawfile.name)
                .getDownloadURL()
                .then((downloadURL) => {
                  resolve(downloadURL);
                })
                .catch((efg) => {
                  setcurrentlyuploading(false);
                  reject(efg);
                });
            })
            .catch((efg) => {
              setcurrentlyuploading(false);
              reject(efg);
            });
        });
        allpromises.push(x);
      }
    });
    Promise.allSettled(allpromises).then((resp) => {
      db.collection("content")
        .doc(selectedalbumtoopen.id)
        .get()
        .then((contentdata) => {
          var existingarray = contentdata.data().contenturl;
          resp.forEach((eachitem) => {
            existingarray.push(eachitem);
          });
          db.collection("content")
            .doc(selectedalbumtoopen.id)
            .update({
              contenturl: existingarray,
            })
            .then((done) => {
              alert("Uploaded");
              setIsOpen5(false);
              setcurrentlyuploading(false);
              setselectednewrawfile(null);
              setnewrawfile(null);
              fetchallcontents();
              setisloading(false);
            })
            .catch((err) => {
              alert(err);
              setcurrentlyuploading(false);
              setisloading(false);
            });
        })
        .catch((err) => {
          alert(err);
          setcurrentlyuploading(false);
          setisloading(false);
        });
    });
  };

  const deleteexistingalbum = async () => {
    // const result = await confirm("Are you sure you want to delete this album?");
    if (window.confirm("Delete the item?")) {
      db.collection("content")
        .doc(selectedalbumtoopen.id)
        .delete()
        .then((done) => {
          alert("Album Deleted");
          setIsOpen5(false);
          fetchallcontents();
        });
    }
  };

  const deleterequested = async (mediaid) => {
    // const result = await confirm("Are you sure you want to delete this media?");
    if (window.confirm("Delete the item?")) {
      db.collection("content")
        .where("contenturl", "==", mediaid)
        .get()
        .then((contdata) => {
          contdata.forEach(function (doc) {
            doc.ref.delete().then((isit) => {
              alert("Media Deleted");
              fetchallcontents();
            });
          });
        });
    }
  };

  const deletealbummediarequested = async (mediaid) => {
    // const result = await confirm("Are you sure you want to delete this media?");
    if (window.confirm("Delete the item?")) {
      db.collection("content")
        .doc(selectedalbumtoopen.id)
        .get()
        .then((contdata) => {
          var conturl = contdata.data().contenturl;
          var copydata = conturl.filter((mk) => mk.value !== mediaid);
          db.collection("content")
            .doc(selectedalbumtoopen.id)
            .update({
              contenturl: copydata,
            })
            .then((df) => {
              alert("Media Deleted");
              setIsOpen5(false);
              fetchallcontents();
            });
        });
    }
  };

  const logouttapped = () => {
    auth.signOut().then((user) => {
      navigate("/mediadownloader");
    });
  };
  const dashboardtapped = () => {
    window.location.href = "https://members.volatilitygame.com/";
  };
  return (
    <div className="homepage">
      {isloading && (
        <div
          className="loader"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fff",
            zIndex: 1020,
            flexDirection: "column",
          }}
        >
          <Grid height="100" width="100" color="#121e1e" ariaLabel="loading" />
          <h4 style={{ color: "#121e1e", letterSpacing: 1 }}>
            Please wait, this might take a while
          </h4>
        </div>
      )}

      {showpositionmodal && (
        <div className="positionmodal">
          <button
            id="close"
            onClick={() => {
              setshowpositionmodal(false);
            }}
          >
            Close
          </button>
          <h4 style={{ marginTop: "60px", padding: "10px" }}>
            Enter Position where you want to place logo
          </h4>
          <div>
            <button
              onClick={() => {
                setpositionmodalposition("TL");
              }}
              className={positionmodalposition === "TL" ? "special" : "normal"}
            >
              Top Left
            </button>
            <button
              onClick={() => {
                setpositionmodalposition("TR");
              }}
              className={positionmodalposition === "TR" ? "special" : "normal"}
            >
              Top Right
            </button>
            <button
              onClick={() => {
                setpositionmodalposition("BL");
              }}
              className={positionmodalposition === "BL" ? "special" : "normal"}
            >
              Bottom Left
            </button>
            <button
              onClick={() => {
                setpositionmodalposition("BR");
              }}
              className={positionmodalposition === "BR" ? "special" : "normal"}
            >
              Bottom Right
            </button>
            <button
              onClick={() => {
                setpositionmodalposition("BLT");
              }}
              className={positionmodalposition === "BLT" ? "special" : "normal"}
            >
              Inside Strip
            </button>
          </div>
          <button id="download" onClick={finalisedownload}>
            Download
          </button>
          <h5
            style={{
              padding: "20px",
              fontSize: "1rem",
              lineHeight: "30px",
              letterSpacing: "2px",
            }}
          >
            Please Ensure you upload your logo and add tagline before
            downloading any media. You can do so by clikcing on user icon at top
            right beside your name.
          </h5>
        </div>
      )}

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Profile"
      >
        <div className="innerview">
          <button
            style={{
              backgroundColor: "#121e1e",
              border: "none",
              color: "white",
              padding: 10,
              margin: 10,
              borderRadius: 10,
            }}
            onClick={() => closeModal()}
          >
            Close
          </button>
          {currentlyuploading ? (
            <h3>Please wait while uploading</h3>
          ) : (
            <h3>Your Profile</h3>
          )}
          <h4>
            Upload your company logo (Try to maintain aspect ratio of logo close
            to 2.5 (width/height) for best experience{" "}
            <span style={{ color: "red" }}>*</span>)
          </h4>
          <img src={selectedImage} width={200} height="auto" />
          <input
            type="file"
            name="myImage"
            accept="image/*"
            onChange={(e) => {
              // setSelectedImage(URL.createObjectURL(e.target.files[0]));
              setrawfile(e.target.files[0]);
            }}
          />
          <h4>Taglines which will be displayed on all downloaded medias</h4>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <div style={{ width: WIDTH_TAG_LINE }}>
              <h5 style={{ margin: 5 }}>
                Tagline 1 <span style={{ color: "red" }}>*</span>
              </h5>
              <textarea
                defaultValue={tagline}
                style={{ width: "100%", height: "50px" }}
                onChange={(e) => settagline(e.target.value)}
              ></textarea>
            </div>
            <div style={{ width: WIDTH_TAG_LINE }}>
              <h5 style={{ margin: 5 }}>Tagline 2</h5>
              <textarea
                defaultValue={tagline2}
                style={{ width: "100%", height: "50px" }}
                onChange={(e) => settagline2(e.target.value)}
              ></textarea>
            </div>
            <div style={{ width: WIDTH_TAG_LINE }}>
              <h5 style={{ margin: 5 }}>Tagline 3</h5>
              <textarea
                defaultValue={tagline3}
                style={{ width: "100%", height: "50px" }}
                onChange={(e) => settagline3(e.target.value)}
              ></textarea>
            </div>
          </div>

          {currentlyuploading ? (
            ""
          ) : (
            <button
              onClick={processupload}
              style={{
                backgroundColor: "#121e1e",
                border: "none",
                color: "white",
                padding: 10,
                margin: 10,
                borderRadius: 5,
              }}
            >
              Update
            </button>
          )}
        </div>
      </Modal>

      <Modal
        isOpen={modalIsOpen2}
        onAfterOpen={afterOpenModal2}
        onRequestClose={closeModal2}
        style={customStyles2}
        contentLabel="Profile"
      >
        <div className="innerview">
          {currentlyuploading ? (
            <h3>Please wait while uploading</h3>
          ) : (
            <h3>Upload New</h3>
          )}
          <h4>Choose Category</h4>
          <select
            onChange={(e) => {
              setselectednewrawfile(null);
              setnewrawfile(null);
              setnewcontentcategory(e.target.value);
            }}
          >
            <option selected={newcontentcategory === "Video"}>Video</option>
            <option selected={newcontentcategory === "Image"}>Image</option>
            <option selected={newcontentcategory === "PDF"}>PDF</option>
            <option selected={newcontentcategory === "Album"}>Album</option>
          </select>
          {newcontentcategory === "PDF" && (
            <div style={{ display: "flex" }}>
              <input
                type="checkbox"
                checked={newsLetterChecked}
                onChange={(e) => {
                  setNewsLetterChecked(e.target.checked);
                }}
              />
              <h5>Is a Newsletter ?</h5>
            </div>
          )}
          {/* <h4>Choose position for logo</h4>
              <select onChange={e => setnewcontentlogoposiiton(e.target.value)}>
                  <option>Top Left</option>
                  <option>Top Right</option>
                  <option>Bottom Left</option>
                  <option>Bottom Right</option>
              </select> */}
          <h4>
            {newcontentcategory === "Album"
              ? "Enter Album Name"
              : "Enter Title"}
          </h4>
          <input
            type="text"
            onChange={(e) => setnewcontenttitle(e.target.value)}
            style={{ width: "40vw", padding: 10 }}
          />
          <h4>
            {newcontentcategory === "Album"
              ? "Upload Album Images"
              : "Upload your content"}
          </h4>
          <input
            type="file"
            name="myImage"
            multiple={newcontentcategory === "Album"}
            onChange={(e) => {
              if (newcontentcategory === "Album") {
                var cbo = [];
                var rawf = [];
                Object.values(e.target.files).map((file) => {
                  cbo.push(URL.createObjectURL(file));
                  rawf.push(file);
                });
                setselectednewrawfile(cbo);
                setnewrawfile(rawf);
              } else {
                setselectednewrawfile(URL.createObjectURL(e.target.files[0]));
                setnewrawfile(e.target.files[0]);
              }
            }}
          />
          {currentlyuploading ? (
            ""
          ) : (
            <button
              onClick={processnewupload}
              style={{
                backgroundColor: "#121e1e",
                border: "none",
                color: "white",
                padding: 10,
                margin: 10,
                borderRadius: 5,
              }}
            >
              Upload
            </button>
          )}
        </div>
      </Modal>

      <Modal
        isOpen={modalIsOpen3}
        onAfterOpen={afterOpenModal3}
        onRequestClose={closeModal3}
        style={customStyles3}
        contentLabel="Profile"
      >
        <div className="innerview" id="innerpdf">
          {modalimage != null ? (
            modalimagetype === "image" ? (
              <img
                alt=""
                src={modalimage}
                style={{
                  width: "50vw",
                  maxHeight: "70vh",
                  objectFit: "contain",
                }}
              />
            ) : (
              <object
                width="600"
                height="750"
                data={modalimage}
                type="application/pdf"
              >
                {" "}
              </object>
            )
          ) : (
            ""
          )}
        </div>
      </Modal>

      <Modal
        isOpen={modalIsOpen4}
        onAfterOpen={afterOpenModal4}
        onRequestClose={closeModal4}
        style={customStyles4}
        contentLabel="Profile"
      >
        <div className="innerview">
          <h4>
            You do not have sufficient downloads left, kindly contact Admin
          </h4>
        </div>
      </Modal>

      <Modal
        isOpen={modalIsOpen6}
        onAfterOpen={afterOpenModal6}
        onRequestClose={closeModal6}
        style={customStyles6}
        contentLabel="Edit"
      >
        <div
          className="innerview"
          style={{
            padding: 10,
            borderRadius: 10,
            height: "90vh",
            overflow: "scroll",
          }}
        >
          <button
            style={{
              backgroundColor: "#121e1e",
              border: "none",
              color: "white",
              padding: 10,
              margin: 10,
              borderRadius: 10,
            }}
            onClick={() => closeModal6()}
          >
            Close
          </button>
          <h4>Enter Title for this media</h4>
          <input
            style={{ width: "40vw", padding: 10 }}
            type="text"
            value={currentEditingValue}
            onChange={(e) => setcurrentEditingValue(e.target.value)}
          />{" "}
          <br></br>
          <button
            style={{
              backgroundColor: "#121e1e",
              border: "none",
              color: "white",
              padding: 10,
              margin: 10,
              borderRadius: 10,
            }}
            onClick={editthisvalue}
          >
            Update
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={modalIsOpen5}
        onAfterOpen={afterOpenModal5}
        onRequestClose={closeModal5}
        style={customStyles5}
        contentLabel="Profile"
      >
        <div
          className="innerview"
          style={{
            padding: 10,
            borderRadius: 10,
            height: "90vh",
            marginTop: MARGIN_TOP_MODAL,
            overflow: "scroll",
          }}
        >
          {selectedalbumtoopen && (
            <div>
              <button
                style={{
                  backgroundColor: "#121e1e",
                  color: "white",
                  border: "none",
                  padding: 10,
                  borderRadius: 5,
                  fontWeight: "bolder",
                  marginRight: 10,
                }}
                onClick={() => {
                  setselectedalbumtoopen(null);
                  setIsOpen5(false);
                }}
              >
                Close
              </button>
              <h4>{selectedalbumtoopen.title}</h4>
              {/* <button
                onClick={doanloadalltapped}
                style={{
                  backgroundColor: "#121e1e",
                  color: "white",
                  border: "none",
                  padding: 10,
                  borderRadius: 5,
                  fontWeight: "bolder",
                  marginRight: 10,
                }}
              >
                Download All Images
              </button> */}
              {isadmin && (
                <div
                  style={{
                    marginTop: 20,
                    backgroundColor: "#f2b42b",
                    padding: 10,
                  }}
                >
                  <input
                    type="file"
                    name="myImage"
                    multiple={newcontentcategory === "Album"}
                    onChange={(e) => {
                      if (newcontentcategory === "Album") {
                        var cbo = [];
                        var rawf = [];
                        Object.values(e.target.files).map((file) => {
                          if (file.type.substring(0, 5) == "image") {
                            cbo.push(URL.createObjectURL(file));
                            rawf.push(file);
                          }
                        });
                        setselectednewrawfile(cbo);
                        setnewrawfile(rawf);
                      } else {
                        setselectednewrawfile(
                          URL.createObjectURL(e.target.files[0])
                        );
                        setnewrawfile(e.target.files[0]);
                      }
                    }}
                  />
                  <button
                    style={{
                      backgroundColor: "#121e1e",
                      color: "white",
                      border: "none",
                      padding: 10,
                      borderRadius: 5,
                      fontWeight: "bolder",
                      marginRight: 10,
                      marginTop: 10,
                    }}
                    onClick={uploadexistingalbum}
                  >
                    Upload Image
                  </button>

                  <button
                    style={{
                      backgroundColor: "#121e1e",
                      color: "white",
                      border: "none",
                      padding: 10,
                      borderRadius: 5,
                      fontWeight: "bolder",
                      marginRight: 10,
                      marginTop: 10,
                    }}
                    onClick={deleteexistingalbum}
                  >
                    Delete Album
                  </button>
                </div>
              )}

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  overflow: "scroll",
                  justifyContent: "center",
                }}
              >
                {selectedalbumtoopen.contenturl.map((eak) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        flexWrap: "wrap",
                        marginTop: 20,
                      }}
                    >
                      <img
                        onClick={() => {
                          setmodalimage(eak.value);
                          setIsOpen3(true);
                          setIsOpen5(false);
                        }}
                        src={eak.value}
                        style={{
                          width: "220px",
                          height: "300px",
                          objectFit: "contain",
                          margin: 20,
                          backgroundColor: "#f0f0f0",
                          borderRadius: 5,
                          borderWidth: "3px",
                          borderColor: "#f2b42b",
                        }}
                      />
                      <div style={{ display: "flex" }}>
                        <button
                          onClick={() =>
                            downloadrequested(
                              { category: "Image", contenturl: eak.value },
                              "image"
                            )
                          }
                          style={{
                            backgroundColor: "#121e1e",
                            color: "white",
                            border: "none",
                            padding: 5,
                            borderRadius: 20,
                            fontWeight: "bolder",
                          }}
                        >
                          Download
                        </button>
                        {isadmin && (
                          <button
                            style={{
                              backgroundColor: "#f2b42b",
                              color: "white",
                              border: "none",
                              padding: 5,
                              borderRadius: 20,
                              fontWeight: "bolder",
                              marginLeft: 5,
                            }}
                            onClick={() => deletealbummediarequested(eak.value)}
                          >
                            Delete
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </Modal>

      <div className="upper">
        <div className="part">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/assets%2Fimages%2F04.png?alt=media&token=322acb3f-ddc0-4159-a542-e080e16a4ed6"
            style={{ width: 60 }}
          />
          <h3 style={{ color: "#2b4a4e", fontSize: "1.2rem" }}>VG CREATIVES</h3>
        </div>
        <div className="userinfo">
          {currentuser != null && isadmin && (
            <button onClick={() => setIsOpen2(true)}>Upload New Content</button>
          )}

          <div style={{ display: "flex", alignItems: "center" }}>
            {currentuser != null && (
              <button
                style={{ borderRadius: 20, paddingLeft: 10, paddingRight: 10 }}
                onClick={() => setIsOpen(true)}
              >
                <img width={20} height={20} src={userIcon} />
              </button>
            )}
            <div>
              <h4>Hi {username}</h4>
              <h5>Downloads Left: {noofdownloadsleft}</h5>
            </div>
            <div className="actions">
              <button onClick={logouttapped}>Logout</button>
              <button onClick={dashboardtapped}>Dashboard</button>
            </div>
          </div>
        </div>
      </div>
      <div className="middle">
        <div>
          <button
            onClick={() => setdisplaycategory("All")}
            className={displaycategory == "All" ? "highlight" : ""}
          >
            All
          </button>
          <button
            onClick={() => setdisplaycategory("Images")}
            className={displaycategory == "Images" ? "highlight" : ""}
          >
            Images
          </button>
          <button
            onClick={() => setdisplaycategory("Videos")}
            className={displaycategory == "Videos" ? "highlight" : ""}
          >
            Videos
          </button>
          <button
            onClick={() => setdisplaycategory("PDF")}
            className={displaycategory == "PDF" ? "highlight" : ""}
          >
            PDF
          </button>

          {/* <button onClick={() =>setdisplaycategory('Gifs')} className={displaycategory == "Gifs" ? 'highlight' : ''}>GIFs</button> */}
        </div>
        <div>
          <input
            type="text"
            placeholder="Enter Search here"
            value={searchterm}
            onChange={(e) => {
              setsearchterm(e.target.value);
              filtercontents(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="lower">
        {displaycategory == "All" &&
          contentsloaded &&
          allfilteredcontents.map((eachcontent) => {
            return eachcontent.category == "Video" ? (
              <div className="eachmedia">
                <video controls>
                  <source src={eachcontent.contenturl} />
                </video>
                <h4>{eachcontent.title}</h4>
                <button onClick={() => downloadrequested(eachcontent, "video")}>
                  Download
                </button>
                {isadmin && (
                  <button onClick={() => editname(eachcontent, "video")}>
                    Edit
                  </button>
                )}
                {isadmin && (
                  <button
                    style={{ backgroundColor: "#f2b42b" }}
                    onClick={() => deleterequested(eachcontent.contenturl)}
                  >
                    Delete
                  </button>
                )}
              </div>
            ) : eachcontent.category == "PDF" ? (
              <div className="eachmedia">
                <div
                  style={{
                    padding: 20,
                    paddingTop: 0,
                    backgroundColor: "white",
                  }}
                  width="90%"
                  height="220"
                >
                  <button
                    style={{ marginBottom: 5 }}
                    onClick={() => {
                      setmodalimagetype("pdf");
                      setmodalimage(eachcontent.contenturl);
                      setIsOpen3(true);
                    }}
                  >
                    View
                  </button>
                  <object
                    width="100%"
                    height="150"
                    data={eachcontent.contenturl}
                    type="application/pdf"
                  >
                    {" "}
                  </object>
                </div>

                <h4>{eachcontent.title}</h4>
                <button onClick={() => downloadrequested(eachcontent, "pdf")}>
                  Download
                </button>
                {isadmin && (
                  <button onClick={() => editname(eachcontent, "pdf")}>
                    Edit
                  </button>
                )}
                {isadmin && (
                  <button
                    style={{ backgroundColor: "#f2b42b" }}
                    onClick={() => deleterequested(eachcontent.contenturl)}
                  >
                    Delete
                  </button>
                )}
              </div>
            ) : eachcontent.category == "Album" ? (
              <div className="eachmedia">
                <div
                  className="wrapper"
                  style={{
                    height: "220px",
                    backgroundColor: "black",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <img
                    src={eachcontent.contenturl[0].value}
                    onClick={() => {
                      // setmodalimage(eachcontent.contenturl);
                      // setIsOpen3(true);
                    }}
                  />
                </div>
                <h4>{eachcontent.title}</h4>
                <button onClick={() => openalbum(eachcontent)}>
                  View Album
                </button>
                {isadmin && (
                  <button onClick={() => editname(eachcontent, "album")}>
                    Edit
                  </button>
                )}
              </div>
            ) : (
              <div className="eachmedia">
                <div
                  className="wrapper"
                  style={{
                    height: "220px",
                    backgroundColor: "black",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <img
                    src={eachcontent.contenturl}
                    onClick={() => {
                      setmodalimage(eachcontent.contenturl);
                      setIsOpen3(true);
                    }}
                  />
                </div>
                <h4>{eachcontent.title}</h4>
                <button onClick={() => downloadrequested(eachcontent, "image")}>
                  Download
                </button>
                {isadmin && (
                  <button onClick={() => editname(eachcontent, "image")}>
                    Edit
                  </button>
                )}
                {isadmin && (
                  <button
                    style={{ backgroundColor: "#f2b42b" }}
                    onClick={() => deleterequested(eachcontent.contenturl)}
                  >
                    Delete
                  </button>
                )}
              </div>
            );
          })}
        {displaycategory == "Images" &&
          contentsloaded &&
          allfilteredcontents
            .filter(
              (each) => each.category == "Image" || each.category == "Album"
            )
            .map((eachcontent) => {
              return eachcontent.category == "Video" ? (
                <div className="eachmedia">
                  <video controls>
                    <source src={eachcontent.contenturl} />
                  </video>
                  <h4>{eachcontent.title}</h4>
                  <button
                    onClick={() => downloadrequested(eachcontent, "video")}
                  >
                    Download
                  </button>
                  {isadmin && (
                    <button onClick={() => editname(eachcontent, "video")}>
                      Edit
                    </button>
                  )}
                  {isadmin && (
                    <button
                      style={{ backgroundColor: "#f2b42b" }}
                      onClick={() => deleterequested(eachcontent.contenturl)}
                    >
                      Delete
                    </button>
                  )}
                </div>
              ) : eachcontent.category == "Album" ? (
                <div className="eachmedia">
                  <div
                    className="wrapper"
                    style={{
                      height: "220px",
                      backgroundColor: "black",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <img
                      src={eachcontent.contenturl[0].value}
                      onClick={() => {
                        // setmodalimage(eachcontent.contenturl);
                        // setIsOpen3(true);
                      }}
                    />
                  </div>
                  <h4>{eachcontent.title}</h4>
                  <button onClick={() => openalbum(eachcontent)}>
                    View Album
                  </button>
                  {isadmin && (
                    <button onClick={() => editname(eachcontent, "album")}>
                      Edit
                    </button>
                  )}
                </div>
              ) : (
                <div className="eachmedia">
                  <div
                    className="wrapper"
                    style={{
                      height: "220px",
                      backgroundColor: "black",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <img
                      src={eachcontent.contenturl}
                      onClick={() => {
                        setmodalimage(eachcontent.contenturl);
                        setIsOpen3(true);
                      }}
                    />{" "}
                  </div>
                  <h4>{eachcontent.title}</h4>
                  <button
                    onClick={() => downloadrequested(eachcontent, "image")}
                  >
                    Download
                  </button>
                  {isadmin && (
                    <button onClick={() => editname(eachcontent, "image")}>
                      Edit
                    </button>
                  )}
                  {isadmin && (
                    <button
                      style={{ backgroundColor: "#f2b42b" }}
                      onClick={() => deleterequested(eachcontent.contenturl)}
                    >
                      Delete
                    </button>
                  )}
                </div>
              );
            })}
        {displaycategory == "Videos" &&
          contentsloaded &&
          allfilteredcontents
            .filter((each) => each.category == "Video")
            .map((eachcontent) => {
              return eachcontent.category == "Video" ? (
                <div className="eachmedia">
                  <video controls>
                    <source src={eachcontent.contenturl} />
                  </video>
                  <h4>{eachcontent.title}</h4>
                  <button
                    onClick={() => downloadrequested(eachcontent, "video")}
                  >
                    Download
                  </button>
                  {isadmin && (
                    <button onClick={() => editname(eachcontent, "video")}>
                      Edit
                    </button>
                  )}

                  {isadmin && (
                    <button
                      style={{ backgroundColor: "#f2b42b" }}
                      onClick={() => deleterequested(eachcontent.contenturl)}
                    >
                      Delete
                    </button>
                  )}
                </div>
              ) : (
                <div className="eachmedia">
                  <div
                    className="wrapper"
                    style={{
                      height: "220px",
                      backgroundColor: "black",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <img
                      src={eachcontent.contenturl}
                      onClick={() => {
                        setmodalimage(eachcontent.contenturl);
                        setIsOpen3(true);
                      }}
                    />{" "}
                  </div>
                  <h4>{eachcontent.title}</h4>
                  <button
                    onClick={() => downloadrequested(eachcontent, "image")}
                  >
                    Download
                  </button>
                  {isadmin && (
                    <button onClick={() => editname(eachcontent, "image")}>
                      Edit
                    </button>
                  )}
                  {isadmin && (
                    <button
                      style={{ backgroundColor: "#f2b42b" }}
                      onClick={() => deleterequested(eachcontent.contenturl)}
                    >
                      Delete
                    </button>
                  )}
                </div>
              );
            })}
        {displaycategory == "PDF" &&
          contentsloaded &&
          allfilteredcontents
            .filter((each) => each.category == "PDF")
            .map((eachcontent) => {
              return eachcontent.category == "PDF" ? (
                <div className="eachmedia">
                  <div
                    style={{
                      padding: 20,
                      paddingTop: 0,
                      backgroundColor: "white",
                    }}
                    width="90%"
                    height="220"
                  >
                    <button
                      style={{ marginBottom: 5 }}
                      onClick={() => {
                        setmodalimagetype("pdf");
                        setmodalimage(eachcontent.contenturl);
                        setIsOpen3(true);
                      }}
                    >
                      View
                    </button>
                    <object
                      width="100%"
                      height="150"
                      data={eachcontent.contenturl}
                      type="application/pdf"
                    >
                      {" "}
                    </object>
                  </div>
                  <h4>{eachcontent.title}</h4>
                  <button onClick={() => downloadrequested(eachcontent, "pdf")}>
                    Download
                  </button>
                  {isadmin && (
                    <button onClick={() => editname(eachcontent, "pdf")}>
                      Edit
                    </button>
                  )}
                  {isadmin && (
                    <button
                      style={{ backgroundColor: "#f2b42b" }}
                      onClick={() => deleterequested(eachcontent.contenturl)}
                    >
                      Delete
                    </button>
                  )}
                </div>
              ) : (
                <div className="eachmedia">
                  <div
                    className="wrapper"
                    style={{
                      height: "220px",
                      backgroundColor: "black",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <img
                      src={eachcontent.contenturl}
                      onClick={() => {
                        setmodalimage(eachcontent.contenturl);
                        setIsOpen3(true);
                      }}
                    />{" "}
                  </div>
                  <h4>{eachcontent.title}</h4>
                  <button
                    onClick={() => downloadrequested(eachcontent, "image")}
                  >
                    Download
                  </button>
                  {isadmin && (
                    <button onClick={() => editname(eachcontent, "image")}>
                      Edit
                    </button>
                  )}
                  {isadmin && (
                    <button
                      style={{ backgroundColor: "#f2b42b" }}
                      onClick={() => deleterequested(eachcontent.contenturl)}
                    >
                      Delete
                    </button>
                  )}
                </div>
              );
            })}
      </div>
    </div>
  );
}
export default Homepage;
